:root {
  --main-color: rgb(104, 117, 217);
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;
  --mark-color: #aaaaaa;
}

.customSlider {
  max-width: 300px;
  margin: auto;
}

.customSlider-track {
  top: 8px;
  border: 1px solid var(--track-color);
  border-radius: 8px;
  height: 18px;
  background: #eaecf0;
}
.customSlider-track-2 {
  top: 8px;
  border: 1px solid var(--track-color);
  border-radius: 8px;
  height: 18px;
  background: #eaecf0;
}
.customSlider-track-3 {
  top: 8px;
  border: 1px solid var(--track-color);
  border-radius: 8px;
  height: 18px;
  background: #eaecf0;
}
.customSlider-track-4 {
  top: 8px;
  border: 1px solid var(--track-color);
  border-radius: 8px;
  height: 18px;
  background: #eaecf0;
}
.customSlider-track-5 {
  top: 8px;
  border: 1px solid var(--track-color);
  border-radius: 8px;
  height: 18px;
  background: #eaecf0;
}

.customSlider-track.customSlider-track-0 {
  background: #3ccb7f;
}

.customSlider-track-2.customSlider-track-2-0 {
  background: #fac515;
  z-index: 1;
}
.customSlider-track-2.customSlider-track-2-1 {
  background: #eaecf0;
  left: 0px !important;
  right: 0px !important;
}
.customSlider-track-3.customSlider-track-3-1 {
  background: #f33d68;
}

.customSlider-track-4.customSlider-track-4-0 {
  background: #f33d68;
}

.customSlider-track-5.customSlider-track-5-1 {
  background: #3ccb7f;
}

.customSlider-thumb {
  cursor: pointer;
  background: white;
  border: #3ccb7f 3px solid;
  color: #3ccb7f;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  outline: none;
}

.customSlider-thumb-2 {
  cursor: pointer;
  background: white;
  border: #fac515 3px solid;
  color: #fac515;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  outline: none;
}

.customSlider-thumb-3 {
  cursor: pointer;
  background: white;
  border: #f33d68 3px solid;
  color: #f33d68;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  outline: none;
}

.customSlider-thumb-4 {
  cursor: pointer;
  background: white;
  border: #f33d68 3px solid;
  color: #f33d68;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  outline: none;
}


.customSlider-thumb-5 {
  cursor: pointer;
  background: white;
  border: #3ccb7f 3px solid;
  color: #3ccb7f;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  outline: none;
}


.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.customSlider-mark {
  top: 12px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
  z-index: 2;
  margin: 0 calc(25px - 18px);
  bottom: calc(50% - 6px);
}

.customSlider-mark.customSlider-mark-before {
  background-color: white;
}

.customSlider-mark.customSlider-mark-active {
  display: none;
}

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 50px;
  border: 1px solid grey;
}

.vertical-slider {
  height: 380px;
  width: 50px;
  border: 1px solid grey;
}
